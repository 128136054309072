<template>
  <ContentLayout>
    <h2>401</h2>
    <h1>Доступ запрещен</h1>
  </ContentLayout>
</template>

<script>
import ContentLayout from "@/layout/ContentLayout.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    ContentLayout,
  },
  computed: {
    ...mapGetters(["isAuthenticated"]),
  },
  watch: {
    isAuthenticated(state) {
      if (state) {
        this.$router.push({ name: "home" });
      }
    },
  },
  created() {
    if (this.isAuthenticated) {
      this.$router.push({ name: "home" });
    }
  },
};
</script>

<style lang="scss" scoped></style>
